import { setRswitchDesktop, setRswitchMobile } from 'utils/rswitcher';

const createClick = (isMobile: boolean) => () => {
  const switcher = isMobile ? setRswitchDesktop : setRswitchMobile;

  switcher();
};

export const createMenuLinks = (isMobile = false) => ([
  {
    title:   isMobile ? 'Полная версия' : 'Мобильная версия',
    code:    'desktop',
    onClick: createClick(isMobile),
  },
  {
    title:  'Реклама',
    href:   'https://reklama.rambler.ru/sites/horoscopes/?about=1',
    target: '_blank',
    code:   'advertising',
  },
  {
    title:  'Редакционная политика',
    href:   'https://help.rambler.ru/news/redakcionnaya_politika/1853/',
    target: '_blank',
    code:   'policy',
  },
  {
    title:  'Условия использования',
    href:   'https://help.rambler.ru/legal/1430/',
    target: '_blank',
    code:   'terms',
  },
  {
    title:  'Политика конфиденциальности',
    href:   'https://help.rambler.ru/legal/1142/',
    target: '_blank',
    code:   'policy',
  },
  {
    title:  'О проекте',
    href:   '/editors/',
    target: '_blank',
    code:   'about',
  },
  {
    title: 'Помощь',
    href:  'https://help.rambler.ru/feedback/horoscopes/',
    // @ts-ignore
    code:  'help',
  },
]);

export const socialLinks = [
  {
    href: 'https://vk.com/rambler',
    code: 'vk',
  },
  {
    href: 'https://ok.ru/rambler',
    code: 'ok',
  },
  {
    href: 'tg://resolve?domain=news_rambler',
    code: 'tg',
  },
  {
    href: 'https://news.rambler.ru/rss/',
    code: 'rss',
  },
];
