import {
  ARTICLES_RUBRICS,
  ArticlesRubricsType,
} from 'config/constants/articlesRubrics';
import {
  SIGN,
  SignType,
} from 'config/constants/sign';
import {
  namesCategories,
  namesCategoriesExtended,
} from 'config/constants/names';

// common
export const date = {
  year:  '\\d{4}',
  month: '\\d{2}',
  day:   '\\d{2}',
};
export const months = 'january|february|march|april|may|june|july|august|september|october|november|december';
export type MonthsType = 'january'
  | 'february'
  | 'march'
  | 'april'
  | 'may'
  | 'june'
  | 'july'
  | 'august'
  | 'september'
  | 'october'
  | 'november'
  | 'december';

// zodiac
export const zodiacSigns = Object.keys(SIGN).join('|');
export type ZodiacSignsType = SignType;
export const zodiacPeriods = 'yesterday|today|tomorrow|weekly|monthly|2019|2020';
export const zodiacPeriodPage = `${zodiacPeriods.replace('today|', '')}|2021|2022|2023|2024`;
export const zodiacSignPeriodPage = `${zodiacPeriods}|2021|2022|2023|2024`;
export type ZodiacAllPeriodsType = 'yesterday'
  | 'today'
  | 'tomorrow'
  | 'weekly'
  | 'next-week'
  | 'monthly'
  | '2019'
  | '2020'
  | '2021'
  | '2022'
  | '2023'
  | '2024';
export const zodiacTypes = 'man|woman|child|element';

// dreams
export const dreamsPeriods = 'son-s-ponedelnika-na-vtornik|son-so-vtornika-na-sredu|son-so-sredy-na-chetverg|son-s-chetverga-na-pyatnicu|son-s-pyatnicy-na-subbotu|son-s-subboty-na-voskresene|son-s-voskresenya-na-ponedelnik';
export const dreamsPeriodsExt = `${dreamsPeriods}|sonnik-po-dnyam`;
export type DreamsPeriodsExtType = 'son-s-ponedelnika-na-vtornik'
  | 'son-so-vtornika-na-sredu'
  | 'son-so-sredy-na-chetverg'
  | 'son-s-chetverga-na-pyatnicu'
  | 'son-s-pyatnicy-na-subbotu'
  | 'son-s-subboty-na-voskresene'
  | 'son-s-voskresenya-na-ponedelnik'
  | 'sonnik-po-dnyam';
export const routeLetters = 'a|b|v|g|d|e|yo|zh|z|i|yi|k|l|m|n|o|p|r|s|t|u|f|h|ts|ch|sh|sch|eh|yu|ya';
export type RouteLettersType = 'a'
  | 'b'
  | 'v'
  | 'g'
  | 'd'
  | 'e'
  | 'yo'
  | 'zh'
  | 'z'
  | 'i'
  | 'yi'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'f'
  | 'h'
  | 'ts'
  | 'ch'
  | 'sh'
  | 'sch'
  | 'eh'
  | 'yu'
  | 'ya';

// longread
export const longreadTopicsList = `${ARTICLES_RUBRICS.join('|')}|longread`;
export type LongreadTopicListType = ArticlesRubricsType | 'longread';

// moon
export const moonPeriods = 'yesterday|tomorrow';
export type MoonPeriodsType = 'yesterday' | 'tomorrow';
export const moonDays = '1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30';
export type MoonDaysType = '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30';

// sun
export const sunPeriods = 'yesterday|tomorrow';
export type SunPeriodsType = 'yesterday' | 'tomorrow';

// chinese
export const chineseSigns = 'rat|ox|tiger|rabbit|dragon|snake|horse|sheep|monkey|rooster|dog|pig';
export const chineseSignsAll = `${chineseSigns}|all`;
export type ChineseSignsType = 'rat'
  | 'ox'
  | 'tiger'
  | 'rabbit'
  | 'dragon'
  | 'snake'
  | 'horse'
  | 'sheep'
  | 'monkey'
  | 'rooster'
  | 'dog'
  | 'pig';
export type ChineseSignsAllType = ChineseSignsType | 'all';
export const chineseYears = '2021|2022|2023|2024';
export type ChineseYearType = '2021' | '2022' | '2023' | '2024';

export const namesCategoriesRoutes = Object.keys(namesCategories).join('|');
export type NamesCategoriesRoutesType = 'beautiful'
  | 'rare'
  | 'popular'
  | 'modern'
  | 'american'
  | 'english'
  | 'russian'
  | 'german'
  | 'jewish'
  | 'slavic'
  | 'french'
  | 'italian'
  | 'tatar'
  | 'armenian'
  | 'muslim'
  | 'orthodox';
export const namesCategoriesExtendedRoutes = Object.keys(namesCategoriesExtended).join('|');
export type NamesCategoriesExtendedRoutesType = NamesCategoriesRoutesType
  | 'girls'
  | 'boys';
export const namesTypes = 'female|male|baby';
export type NamesTypesBaseType = 'female' | 'male';
export type NamesTypesType = NamesTypesBaseType | 'baby';
export const namesTypesExtended = 'female|male|baby|boys|girls';
export type NamesTypesExtendedType = NamesTypesType | 'boys' | 'girls';

// human design
export const humanDesignTypes = 'proektor|manifestor|generator|manifestiruyushhij-generator|reflektor';
export const humanDesignProfiles = '1-3|1-4|2-4|2-5|3-5|3-6|4-6|4-1|5-1|5-2|6-2|6-3';

// hair
export const hairDates = `${moonDays}|31`;

export const STRICT_CONTENT_PAGES = ['mailing'];
