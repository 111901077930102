export const ARTICLES_RUBRICS = [
  'psychology-articles',
  'dreams-articles',
  'zodiac-articles',
  'names-articles',
  'numerology-articles',
  'eastern-articles',
  'horoscopes-articles',
  'moon-articles',
  'fortune-articles',
  'dizain-cheloveka-articles',
  'kalendar-majya-articles',
  'retrogradnyj-merkurij-articles',
  'vanga-articles',
  '2020-articles',
  '2021-articles',
  'astro-school-articles',
  'predictions-articles',
  'folk-calendar-articles',
  'tests-articles',
  'chihalka-articles',
  'primety-articles',
  'zagovory-articles',
  '2022-articles',
  '2023-articles',
  'chesalka-articles',
  'yoga-articles',
  '2024-articles',
  'projectnews-articles',
  'hiromantiya-articles',
  'afterlife-articles',
  'zagovory-ritualy-articles',
];

export type ArticlesRubricsType = 'psychology-articles'
  | 'dreams-articles'
  | 'zodiac-articles'
  | 'names-articles'
  | 'numerology-articles'
  | 'eastern-articles'
  | 'horoscopes-articles'
  | 'moon-articles'
  | 'fortune-articles'
  | 'dizain-cheloveka-articles'
  | 'kalendar-majya-articles'
  | 'retrogradnyj-merkurij-articles'
  | 'vanga-articles'
  | '2020-articles'
  | '2021-articles'
  | 'astro-school-articles'
  | 'predictions-articles'
  | 'folk-calendar-articles'
  | 'tests-articles'
  | 'chihalka-articles'
  | 'primety-articles'
  | 'zagovory-articles'
  | '2022-articles'
  | '2023-articles'
  | 'chesalka-articles'
  | 'yoga-articles'
  | '2024-articles'
  | 'projectnews-articles'
  | 'hiromantiya-articles'
  | 'afterlife-articles'
  | 'zagovory-ritualy-articles';
